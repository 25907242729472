import getConfig from 'next/config'
import nookies from 'nookies'
import {
  impressionTypes,
  getImpressionData,
  clearTrackedData,
} from './impressionsTracker'
import { post, headers } from './fetch'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import SessionStorageService from '../utils/SessionStorageService'
import { formatCtaLocation, getPageTypeByUrl } from './amplitude'
import { getCtaLocationMeta } from './utils'
import { AUTH_TOKEN_KEY } from '../utils/constants'
const {
  publicRuntimeConfig: { API_URL, RELEASEID },
} = getConfig()

const getXSessionId = () => {
  const sessionId = SessionStorageService.getItem('tt_sessionId')
  if (sessionId) {
    return sessionId.replace(/"/g, '')
  } else {
    return null
  }
}

const headersForCitrus = () => {
  const sessionId = getXSessionId()
  return {
    ...headers(),
    ...(sessionId ? { 'x-session-id': sessionId } : {}),
  }
}
const isCitrusProductTrackingEnabled = (remoteConfig = {}) => {
  let citrusConfig = remoteConfig?.citrusAdConfig?.defaultValue?.value || {}
  citrusConfig =
    typeof citrusConfig === 'string' ? JSON.parse(citrusConfig) : citrusConfig

  return !!citrusConfig?.enableProductTracking || false
}

const trackCitrusAdProductClick = (adIds = [], remoteConfig = {}) => {
  const validAdIds = compact(adIds).map(String)
  if (!isEmpty(validAdIds) && isCitrusProductTrackingEnabled(remoteConfig)) {
    const requestUrl = `${API_URL}/marketing/tracking`
    post(requestUrl, {
      headers: headersForCitrus(),
      body: JSON.stringify({
        type: 'Clicks',
        adIds: validAdIds,
        platform: 'web',
        trackingData: citrusTrackingDataPayload(),
      }),
    })
  }
}

const trackCitrusAdProduct = (remoteConfig = {}) => {
  if (isCitrusProductTrackingEnabled(remoteConfig)) {
    const citrusProductImpressionData = getImpressionData(
      impressionTypes.CITRUS_PRODUCT_IMPRESSION
    )

    if (citrusProductImpressionData.length) {
      const requestUrl = `${API_URL}/marketing/tracking`
      post(requestUrl, {
        headers: headersForCitrus(),
        body: JSON.stringify({
          type: 'Impressions',
          adIds: citrusProductImpressionData,
          platform: 'web',
          trackingData: citrusTrackingDataPayload(),
        }),
      })
      clearTrackedData(impressionTypes.CITRUS_PRODUCT_IMPRESSION)
    }
  }
}

const trackCitrusBannerClick = ({ banner }) => {
  const requestUrl = `${API_URL}/marketing/tracking`
  post(requestUrl, {
    headers: headersForCitrus(),
    body: JSON.stringify({
      type: 'Clicks',
      adIds: [banner.adId.toString()],
      platform: 'web',
      trackingData: citrusTrackingDataPayload(),
    }),
  })
}

const trackCitrusBannerImpressions = () => {
  const citrusBannerImpressionData = getImpressionData(
    impressionTypes.CITRUS_BANNER_IMPRESSION
  )
  if (citrusBannerImpressionData.length) {
    const requestUrl = `${API_URL}/marketing/tracking`
    post(requestUrl, {
      headers: headersForCitrus(),
      body: JSON.stringify({
        type: 'Impressions',
        adIds: citrusBannerImpressionData,
        platform: 'web',
        trackingData: citrusTrackingDataPayload(),
      }),
    })
    clearTrackedData(impressionTypes.CITRUS_BANNER_IMPRESSION)
  }
}

const citrusTrackingDataPayload = () => {
  const getFormattedTimestamp = () => {
    const now = new Date()
    return now.toISOString().slice(0, 19) // Removes milliseconds and 'Z' at the end
  }

  const { [AUTH_TOKEN_KEY]: authToken } = nookies.get() || {}
  const newUser =
    window?.__NEXT_DATA__?.props?.account && authToken
      ? window?.__NEXT_DATA__?.props?.account?.id
      : 0

  const CTA = {
    pageName: getPageTypeByUrl(),
    pageMeta: getCtaLocationMeta() || [],
  }
  const screenName = formatCtaLocation(CTA, 'grocery-online')
  return {
    'build_number': '',
    'device_id': '',
    'os_version': '',
    'ad_location': screenName,
    'app_version': RELEASEID,
    'user_id': newUser,
    'timestamp': getFormattedTimestamp(),
    'platform': 'web',
  }
}

export {
  getXSessionId,
  headersForCitrus,
  isCitrusProductTrackingEnabled,
  trackCitrusAdProductClick,
  trackCitrusAdProduct,
  trackCitrusBannerClick,
  trackCitrusBannerImpressions,
  citrusTrackingDataPayload,
}
