import { getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'

const getRatingsConfig = () => {
  const { isEnabled, configuration } = getConfigFor(SPLIT_FEATURES.RATINGS)

  let ratingsConfig = { isFeatureEnabled: isEnabled }
  if (configuration) {
    ratingsConfig = {
      ...ratingsConfig,
      ...configuration,
    }
  }
  return ratingsConfig
}

export { getRatingsConfig }
